// src/components/Layout.js
import React from 'react';
import NavBar from './NavBar';
// We need to import the css file in order to use the styles
// It is in a folder called css inside the src folder
import './css/Layout.css';


const Layout = ({ children }) => {
    return (
        <div className="App">
            <NavBar />
            <div className="content">
                {children}
            </div>
        </div>
    );
};

export default Layout;
