import Layout from '../components/Layout';
import './css/Contact.css';


const Contact = () => {
    return (
        <Layout>
            <div className="contact-container">
                <h1>For any information please email me at:</h1>
                <h3>iramosibx@gmail.com</h3>
            </div>
        </Layout>
    );
};

export default Contact;
