import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useState, useEffect, useRef, useCallback } from 'react';
import './css/PremiumPage.css';
import Layout from '../components/Layout';

const PremiumPage = () => {
    const [oauthUrlFetched, setOAuthUrlFetched] = useState(false);
    const [instagramPosts, setInstagramPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const instagramContainerRef = useRef(null);
    const commentsContainerRef = useRef(null);
    const [isSending, setIsSending] = useState(false);
    const [sendStatus, setSendStatus] = useState(null);
    const [isFacebookLoading, setIsFacebookLoading] = useState(false);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const updateCommentsHeight = () => {
        if (instagramContainerRef.current && commentsContainerRef.current) {
            const instagramHeight = instagramContainerRef.current.offsetHeight;
            commentsContainerRef.current.style.height = `${instagramHeight}px`;
        }
    };

    const fetchInstagramPosts = useCallback(async () => {
        try {
            const session = await fetchAuthSession();
            let accessToken = session.tokens.accessToken.toString();
            const response = await fetch('https://api.cleancomment.com/ig/post', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch Instagram Posts');
            }

            const data = await response.json();
            const postsWithCounts = await Promise.all((data.data || []).map(async (post) => {
                const commentsCount = await fetchCommentsCount(post.id);
                const likesCount = await fetchLikesCount(post.id, accessToken);
                return { 
                    ...post, 
                    comments_count: commentsCount, 
                    like_count: likesCount || 0,
                    caption: post.caption || ''
                };
            }));
            setInstagramPosts(postsWithCounts);
            return postsWithCounts;
        } catch (error) {
            console.error('Error fetching Instagram Posts:', error);
            setInstagramPosts([]);
            return [];
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateCommentsHeight);
        return () => window.removeEventListener('resize', updateCommentsHeight);
    }, []);

    useEffect(() => {
        updateCommentsHeight();
    }, [selectedPost]);

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const session = await fetchAuthSession();
                if (session) {
                    await getUsernames();
                    await fetchInstagramPosts(); // Fetch posts when the user is authenticated
                }
            } catch (error) {
                console.error('Error checking auth status:', error);
            }
        };

        checkAuthStatus();
    }, [fetchInstagramPosts]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const justLoggedIn = urlParams.get('logged_in');
        const errorParam = urlParams.get('error');
        const errorDescription = urlParams.get('error_description');

        if (justLoggedIn === 'true') {
            getUsernames();
            fetchInstagramPosts(); // Fetch posts when user just logged in
        }

        if (errorParam) {
            setErrorMessage(decodeURIComponent(errorDescription));
        }
    }, [fetchInstagramPosts]);

    const fetchOAuthUrl = async () => {
        try {
            const session = await fetchAuthSession();
            let accessToken = session.tokens.accessToken.toString();
            const response = await fetch('https://api.cleancomment.com/facebook/login', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch OAuth URL');
            }

            const data = await response.json();
            const authUrl = data.authUrl;
            setOAuthUrlFetched(true);
            return authUrl;
        } catch (error) {
            console.error('Error fetching OAuth URL:', error);
            throw error;
        }
    };

    const callOauthApi = async () => {
        try {
            setIsFacebookLoading(true);
            if (!oauthUrlFetched) {
                await fetchOAuthUrl();
            }
            window.location.href = await fetchOAuthUrl();
        } catch (error) {
            console.error('Error calling API:', error);
        } finally {
            setIsFacebookLoading(false);
        }
    };


    const fetchCommentsCount = async (postId) => {
        try {
            const session = await fetchAuthSession();
            let accessToken = session.tokens.accessToken.toString();
            const response = await fetch(`https://api.cleancomment.com/ig/post/comments`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ media_id: postId })
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch Instagram Post Comments');
            }
    
            const data = await response.json();
            setSelectedPost(prevPost => prevPost ? { ...prevPost, comments_count: data.comments_count } : null);
            return data.comments_count || 0;
        } catch (error) {
            console.error('Error fetching Instagram Post Comments:', error);
            return 0;
        }
    };

    const fetchLikesCount = async (postId, accessToken) => {
        try {
            const response = await fetch(`https://api.cleancomment.com/ig/post/likes`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ media_id: postId })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch likes count');
            }

            const data = await response.json();
            return data.likes;
        } catch (error) {
            console.error('Error fetching likes count:', error);
            return 0;
        }
    };

    const fetchComments = async (postId) => {
        try {
            const session = await fetchAuthSession();
            let accessToken = session.tokens.accessToken.toString();
            const response = await fetch(`https://api.cleancomment.com/ig/post/comments/details`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ media_id: postId })
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch Instagram Post Comments Details');
            }
    
            const data = await response.json();
            return data.data || [];
        } catch (error) {
            console.error('Error fetching Instagram Post Comments Details:', error);
            return [];
        }
    };

    const handlePostSelect = async (post) => {
        setSelectedPost(post);
        if (post && post.id) {
            const comments = await fetchComments(post.id);
            setSelectedPost(prevPost => ({ ...prevPost, comments }));
        }
    };

    const handleCommentSelect = (id) => {
        setSelectedCommentId(id);
    };

    const sendSelectedComment = async () => {
        try {
            setIsSending(true);
            setSendStatus(null);
            const session = await fetchAuthSession();
            let accessToken = session.tokens.accessToken.toString();
            const response = await fetch('https://api.cleancomment.com/ig/post/comments/delete', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ comment_id: selectedCommentId })
            });
    
            // Primero, habilitamos el botón
            setIsSending(false);
    
            // Luego, esperamos un tick para asegurarnos de que el cambio se ha aplicado
            await new Promise(resolve => setTimeout(resolve, 0));
    
            if (response.status === 200) {
                setSendStatus('success');
                await updateCommentsAfterDeletion();
            } else {
                setSendStatus('error');
            }
    
        } catch (error) {
            console.error('Error sending selected comment:', error);
            setIsSending(false);
            await new Promise(resolve => setTimeout(resolve, 0));
            setSendStatus('error');
        } finally {
            setTimeout(() => {
                setSendStatus(null);
                setSelectedCommentId(null);
            }, 2000);
        }
    };
    const updateCommentsAfterDeletion = async () => {
        if (selectedPost && selectedPost.id) {
            const updatedComments = await fetchComments(selectedPost.id);
            setSelectedPost(prevPost => ({ ...prevPost, comments: updatedComments }));
            setSelectedCommentId(null); // Reseteamos el comentario seleccionado
        }
    };

    const getUsernames = async () => {
        try {
            const session = await fetchAuthSession();
            let accessToken = session.tokens.accessToken.toString();

            const response = await fetch('https://api.cleancomment.com/ig/username', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.ok) {
                const data = await response.json();
                setWelcomeMessage(`Welcome, @${data.instagram_username}!`);
            } else {
                throw new Error('Failed to fetch usernames');
            }
        } catch (error) {
            console.error('Error fetching usernames:', error);
        }
    };


    return (
        <Layout>
            <Authenticator>
                {({ signOut }) => (
                    <div className={`container ${selectedPost ? 'content-loaded' : ''}`}>
                        <div className="logo-container">
                            <img src="clean_comment.png" alt="Clean Comment Logo" />
                        </div>
                        {welcomeMessage && <h2>{welcomeMessage}</h2>}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <button className='button' onClick={signOut}>Sign out</button>
                        <button 
                            className='button' 
                            onClick={callOauthApi}
                            disabled={isFacebookLoading}
                        >
                            {isFacebookLoading ? 'Loading...' : 'Login to Facebook'}
                        </button>
                        {/* <button 
                            className='button' 
                            onClick={callInstagramApi} 
                            disabled={isLoading}
                        >
                            {isLoading ? 'Loading...' : 'Fetch Instagram Post'}
                        </button> */}
                        {!selectedPost ? (
                            <div className="posts-table-container">
                                <h2>Instagram Posts</h2>
                                <table className="posts-table">
                                    <thead>
                                        <tr>
                                            <th>Preview</th>
                                            <th>Caption</th>
                                            <th>Comments</th>
                                            <th>Likes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {instagramPosts.map(post => (
                                            <tr key={post.id} onClick={() => handlePostSelect(post)}>
                                                <td>
                                                    {post.media_url ? (
                                                        <img src={post.media_url} alt={post.caption || 'No caption'} className="post-preview" />
                                                    ) : (
                                                        <span>No image</span>
                                                    )}
                                                </td>
                                                <td>{post.caption || 'No caption'}</td>
                                                <td>{post.comments_count || 0}</td>
                                                <td>{post.like_count || 0}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="content-container">
                                <div className="instagram-container" ref={instagramContainerRef}>
                                    <img
                                        src={selectedPost.media_url}
                                        alt="Instagram Post"
                                        onLoad={updateCommentsHeight}
                                    />
                                </div>

                                <div className="comments-container" ref={commentsContainerRef}>
                                    {selectedPost.comments && selectedPost.comments.map(comment => (
                                        <label key={comment.id}>
                                            <input
                                                type="radio"
                                                name="comment"
                                                value={comment.id}
                                                onChange={() => handleCommentSelect(comment.id)}
                                            />
                                            <span className="comment-text">{comment.text}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        )}
                        {selectedCommentId && (
                            <button 
                                className={`button send-comment-button ${sendStatus ? sendStatus : ''}`}
                                onClick={sendSelectedComment}
                                disabled={isSending}
                            >
                                <span className="button-text">
                                    {isSending ? 'Deleting...' : 'Delete comment'}
                                </span>
                                {sendStatus === 'success' && (
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                    </svg>
                                )}
                                {sendStatus === 'error' && (
                                    <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="crossmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                        <path className="crossmark__cross" fill="none" d="M16 16 36 36 M36 16 16 36"/>
                                    </svg>
                                )}
                            </button>
                        )}
                    </div>
                )}
            </Authenticator>
        </Layout>
    );
};

export default PremiumPage;
