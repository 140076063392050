import Layout from '../components/Layout';
import './css/Home.css';

const Home = () => {
    return (
        <Layout>
            <div className="home-logo-container">
                <img src="clean_comment.png" alt="Clean Comment Logo" />
            </div>
            <div className="home-container">
                <h1>Welcome!!</h1>
                <h3>Please access the Premium page to login</h3>
                <p className="disclaimer">
                    <strong>Note:</strong> This application is intended for use by Instagram Business Accounts only. Personal Instagram accounts are not supported.
                </p>
            </div>
        </Layout>
    );
};

export default Home;
