// src/components/NavBar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './css/NavBar.css';


const NavBar = () => {
    return (
        <nav className="navbar">
            <NavLink to="/" activeclassname="active">
                Home
            </NavLink>
            <NavLink to="/premium" activeclassname="active">
                Premium
            </NavLink>
            <NavLink to="/contact" activeclassname="active">
                Contact
            </NavLink>
        </nav>
    );
};

export default NavBar;
